import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { useGlobalContext } from 'store/GlobalProvider';
import { useMount } from 'react-use';
import { SliceZone } from 'components/SliceZone';

const Page = ({ data }) => {
  const { setMenuTitle } = useGlobalContext();

  useMount(() => {
    const title = data?.prismicPage?.data?.title?.text;

    if (title) {
      setMenuTitle(title);
    }
  });

  if (!data?.prismicPage?.data) return <h1>No data on home template</h1>;

  const { body } = data.prismicPage.data;

  return (
    <Module>
      <SEO {...data.prismicPage.data} />
      <SliceZone allSlices={body} />
    </Module>
  );
};

const Module = styled.div``;

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query PageQuery($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
        body {
          ...SliceHeroPage
          ...SliceEditorialPage
          ...SliceTextPage
          ...SliceTestimonialsPage
          ...SlicePushPage
          ...SliceThumbnailsPage
          ...SliceThumbnailFullPage
          ...SliceListPage
          ...SliceGalleryPage
          ...SliceCreditsPage
          ...SliceGalleryScrollPage
          ...SliceRelatedsPage
          ...SliceCarouselPushPage
          ...SliceVimeoPage
        }
      }
    }
  }
`;
