import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { SliceZone } from 'components/SliceZone';
import { useMount } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';

const Home = ({ data }) => {
  const { setMenuTitle } = useGlobalContext();

  useMount(() => {
    const title = data?.prismicHome?.data?.title?.text;

    if (title) {
      setMenuTitle(title);
    }
  });

  if (!data?.prismicHome?.data) return <h1>No data on home template</h1>;

  const { body } = data.prismicHome.data;

  return (
    <Module>
      <SEO {...data.prismicHome.data} />
      <SliceZone allSlices={body} />
    </Module>
  );
};

const Module = styled.div``;

export default withPrismicPreview(Home, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query HomeQuery($uid: String!, $lang: String!) {
    prismicHome(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
        body {
          ...SliceHeroHome
          ...SliceEditorialHome
          ...SliceTextHome
          ...SliceTestimonialsHome
          ...SlicePushHome
          ...SliceThumbnailsHome
          ...SliceThumbnailFullHome
          ...SliceListHome
          ...SliceGalleryHome
          ...SliceCreditsHome
          ...SliceGalleryScrollHome
          ...SliceRelatedsHome
          ...SliceCarouselPushHome
          ...SliceVimeoHome
          ...SliceLogosHome
        }
      }
    }
  }
`;
