import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { Button } from 'components/Button';
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { fontUltraCondensed, Title5 } from 'styles/typography';
import { rem } from 'styles/utils';
import Home from './home';
import Page from './page';

const Page404 = ({ data }) => {
  if (!data?.prismic404?.data) return <h1>Page not found</h1>;
  const { title, baseline, link_text, link } = data.prismic404.data;
  const hasTitle = !!title?.text;
  const hasBaseline = !!baseline?.text;
  const hasLink = !!link?.url && !!link_text;
  return (
    <Module>
      <SEO {...data.prismic404.data} />
      <Content>
        <div>
          {hasTitle && <Title>{title.text}</Title>}
          {hasBaseline && <Title5>{baseline.text}</Title5>}
          {hasLink && (
            <Button color="white" icon {...link}>
              {link_text}
            </Button>
          )}
        </div>
      </Content>
    </Module>
  );
};

export default withPrismicUnpublishedPreview(Page404, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      home: Home,
      page: Page,
    }),
  },
]);

const Title = styled.h1`
  ${fontUltraCondensed};
  font-size: ${(400 / 900) * 100}vh;
  font-weight: normal;
  line-height: 1;
  color: ${({ theme }) => theme.colors.accent};
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 ${rem(48)};
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${Button} {
    margin-top: ${rem(48)};
  }
`;

const Module = styled.div``;

export const pageQuery = graphql`
  query Page404Query($uid: String!, $lang: String!) {
    prismic404(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
        baseline {
          text
        }
        link {
          url
          target
        }
        link_text
      }
    }
  }
`;
